
    <!--  技术平台 -->
<template>
  <div class="techPlatform">
    <product-banner :bannerImg="bannerImg" :bannerTitleData="bannerTitleData" />
    <cases-title :titleVal="'技术平台'" />
    <!-- 低代码平台 -->
    <div class="low-code-container">
      <div class="subhead">低代码平台</div>
      <div class="subhead-desc">{{ `宝晟C-Lims，基于低代码工具的解决方案，是一个专门针对生物医药领域而研发的基础软件框架平台，可为用户提供各类管理系统（样本库管理系统、实验室管理系统
              、仪器预约使用管理系统、科研课题管理系统、模式动物管理系统、NGS管理系统、细胞治疗管理系统等）均可基于此平台而实现。平台采用虚拟机技术，同时支持VM
              ware player和Hyper-V虚拟机播放。虚拟机采用稳定的开源LINUX作为操作系统，将数据库与平台系统共同组包于虚拟机中，无需额外安装，充分保证系统运行的安全
              性和稳定性。平台内置开源Mongodb数据库，并支持使用外置Mongodb或Oracle或PostgreSQL数据库。`| myTrim
      }}</div>
      <img class="low-code-img" src="@/assets/images/products/techPlatform-img01.png" alt="">
      <div class="small-title">
        1、实现B2B（业务对业务）模式
      </div>
      <div class="small-title-desc">
        {{ ` C-Lims平台为各种管理系统提供基础的、健壮的、灵活的数据及流程管理核心功能，每一套业务系统的实施由具有生物医药专业背景的实施人员，集中精力关注用户的
                业务需求即可，这可极大地缩短实施周期，让用户能更快地用上系统。`| myTrim
        }}
      </div>
      <div class="small-title-of-img">
        <div class="title"> 2、实现热插拔模式</div>
        <div class="desc">
          {{ `在C-Lims平台上实现的功能模块、业务流程，当需要更改或维护时，可以单独“下线”进行更改或维护，快速实现功能、业务流程的变更和优化而不会影响正在正常使用
                    的其他模块或流程。在C-Lims同一个平台上，可同时运行多套管理系统（如：同时运行样本库管理系统及动物管理系统），信息数据互通互联。`| myTrim
          }}
        </div>
        <img src="@/assets/images/products/techPlatform-img02.png" alt="">

      </div>

    </div>
    <!-- paas平台-->
    <div class="paas-container">
      <div class="subhead">{{ paasData.name }}</div>
      <div class="subhead-desc">{{ paasData.desc | myTrim }}</div>
      <div class="paas-item" v-for="(item, index) in paasData.advantageList" :key="index">
        <div class="desc">{{ item.desc | myTrim }}</div>
        <img v-show="item.img" :src="item.img" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import productBanner from './components/ProductsBanner.vue'
import casesTitle from "@/views/cases/components/Title.vue"
export default {
  components: {
    productBanner,
    casesTitle
  },
  data() {
    return {
      bannerImg: require("@/assets/images/products/products-techPlatform.png"),
      bannerTitleData: {
        zn: "技术平台",
        en: "Platform Overview"
      },
      paasData: {
        name: "Paas平台",
        desc: "宝晟云平台，运用云计算、大数据、物联网、移动互联和 AI 等技术为生物科学领域用户构建企业应用的集成服务平台。",
        advantageList: [
          {
            desc: "1、采用微服务框架，搭建“小应用大平台”架构基于Spring Cloud 微服务技术框架，搭建一个“大”的云平 台对业务进行统一支撑，避免系统间重复造轮子，从而可以将复杂的业务系统进行瘦身并拆分成“小”的微应用，这样才能更好适应灵活多变的各种应用场景。 ",
            img: null
          },
          {
            desc: "2、搭建“1 个账号+1 个平台+N 个终端”应用模式，运用云计算、大数据、物联网、移动互联和 AI 等技术，建立统一的用户中心，通过一个云平台让人与人、人与企业、企业与企业连接起来，让业务在PC端、移动端、智能设备、监控大屏等不同终端、不同场景之间协同起来，提高工作效率、提升用户体验。",
            img: require("@/assets/images/products/techPlatform-img03.png")
          },
          {
            desc: "3、 6 大中心让企业级应用开发像“搭积木”一样简单，组织中心、流程中心、认证中心、应用中心、表单中心、消息中心等6大中心，可以帮助企业以“低代码”的开发方式快速搭建一个企业级应用，大大缩短开发时间、节约开发成本",
            img: null
          },
          {
            desc: "4、宝晟云APP 让碎片化的移动应用“化零为整”，宝晟云APP既是一个协同办公APP，也是一个移动应用集成平台，让用户只需安装一个APP 就能享受一站式移动服务体验。",
            img: null
          },
          {
            desc: "5、“混合云”开发模式，充分保护客户的数据安全。采用混合云的应用开发和部署模式，为企业大大节省基础平台的建设成本，不但能缩短建设周期、减低建设风险，还能兼顾企业对于业务数据安全性保护的需要。",
            img: null
          },
          {
            desc: "6、运用服务编排和容器化技术，让平台运行“稳如泰山” 运用服务编排（K8S）和容器（Docker）等云原生技术，实现平台能够弹性伸缩和灰度升级，提供24小时不中断的高可用、高性能的服务。",
            img: null
          },
        ]
      }
    }
  }
}
</script>

<style lang="less" scoped>
.subhead {
  text-align: left;
  height: 40px;
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
  line-height: 40px;
  margin-bottom: 16px;
}

.subhead-desc {
  text-align: left;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
  letter-spacing: 1px;
  text-align: justify;
}

.low-code-container {
  width: 1200px;
  margin: 0 auto;
  text-align: center;



  .small-title {
    text-align: left;
    height: 30px;
    font-size: 22px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #000000;
    line-height: 30px;
  }

  .low-code-img {
    margin: 32px 0;
  }

  .small-title-desc {
    text-align: left;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 30px;
    letter-spacing: 1px;
    margin: 10px 0 32px;
    text-align: justify;
  }

  .small-title-of-img {
    text-align: center;

    .title {
      text-align: left;
      text-align: left;
      height: 30px;
      font-size: 22px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #000000;
      line-height: 30px;
    }

    .desc {
      text-align: left;
      text-align: left;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
      letter-spacing: 1px;
      margin: 10px 0 32px;
      text-align: justify;
    }
  }
}


.paas-container {
  width: 1200px;
  margin: 0 auto;

  .paas-item {
    text-align: center;

    .desc {
      font-size: 17px;
      font-weight: normal;
      color: #000000;
      line-height: 30px;
      letter-spacing: 1px;
      margin: 30px 0;
      text-align: justify;
    }
  }
}
</style>
