<!-- 解决方案 公共标题组件 -->
<template>
  <div class="title">
    <div class="title-container">
      <img class="img" :src="imgLeft">
      <div class="title-text">{{ titleVal }}</div>
      <img class="img" :src="imgRight">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titleVal: {
      type: String,
      default: "默认标题",
    }
  },
  data() {
    return {
      imgLeft: require("@/assets/images/cases/title/left.png"),
      imgRight: require("@/assets/images/cases/title/right.png"),
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  max-width: 1920px;
  margin: 0 auto;
  padding: 68px 0 57px;
  display: flex;

  .title-container {
    display: flex;
    align-items: center;
    margin: 0 auto;

    .img {
      display: block;
      width: 77px;
      height: 11px;
    }

    .title-text {
      height: 37px;
      font-size: 28px;
      font-weight: bold;
      color: #000000;
      line-height: 37px;
      margin: 0 20px;
    }
  }
}
</style>